.UserGuide-article {
  overflow-y: auto;
  height: calc(100vh - 95px);
  min-width: 390px;
  padding: 40px 120px;
  margin: 0 0 15vh 0;
}

.UserGuide-article p {
  font-size: 16px;
  font-weight: 400;
}
